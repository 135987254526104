import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import { MainUrl } from "../utils/mainURL";
import "./custom.css";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";


const Dashboard = () => {
  // AOS.init()
  const [Preloader, setPreloader] = useState(true);
  const [isAuthenticate, setAuthenticate] = useState(true);
  const [Orders, setOrders] = useState([]);
  const [DropDownState, setDropDownState] = useState("All requests");
  const [DropDownStateFinal, setDropDownStateFinal] = useState();
  const [ItemSelect, setItemSelect] = useState({});
  const [show, setShow] = useState(false);
  const [MessageBox, setMessageBox] = useState("");
  const [UserId, setUserId] = useState();

  useEffect(() => {
    var link = "Form/getOrders";
    axios
      .post(`${MainUrl + link}`)
      .then((res) => {
        setPreloader(false);
        setOrders(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setPreloader(false);
      });
  }, []);

  const acceptRejectNotify = (userId, status) => {
    if (status === 1) {
      var message = 'Your request is accepted. We will contact you soon.'
    }
    else if (status === 2) {
      var message = 'Your request is rejected.'
    }

    else if (status === 3) {
      var message = 'Your request is In-Process.'
    }

    else if (status === 4) {
      var message = 'Your request is Completed.'
    }

    var link = "Notification/sendNotification";
    var data = new FormData();
    data.append("message", message);
    data.append("userId", userId);
    axios
      .post(`${MainUrl + link}`, data)
      // .then((res) => {})
      .catch((err) => {
        console.log(err);
        // setPreloader(false);
      });
  }

  useEffect(() => {
    var link = "Form/updateOrders";
    var data = new FormData();
    if (DropDownStateFinal) {
      if (DropDownStateFinal === "Accepted") {
        var status = 1;
      } else if(DropDownStateFinal === "Rejected") {
        var status = 2;
      }
      else if(DropDownStateFinal === "In-Process") {
        var status = 3;
      }
      else if(DropDownStateFinal === "Completed") {
        var status = 4;
      }

      data.append("orderId", ItemSelect._id);
      data.append("status", status);
      data.append('userId', ItemSelect.userId)

      // console.log("🚀 ~ file: dashboard.js ~ line 58 ~ useEffect ~ data", data) 
      axios
        .post(`${MainUrl + link}`, data)
        .then((res) => {
          acceptRejectNotify(ItemSelect.userId, status)
          var link = "Form/getOrders";
          axios
            .post(`${MainUrl + link}`)
            .then((res) => {
              setPreloader(false);
              setOrders(res.data.data);
            })
            .catch((err) => {
              console.log(err);
              setPreloader(false);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [DropDownStateFinal]);

  useEffect(() => {
    const token = localStorage.getItem("userName");
    if (token == null) {
      setAuthenticate(false);
    }
  }, [isAuthenticate]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const msgButton = (userId) => {
    // alert(userId)
    setShow(true);
    setUserId(userId);
  };

  const sendMessage = () => {
    if (!MessageBox) {
      alert("Can't send empty message.");
    } else {
      setShow(false);
      setMessageBox("");
      var link = "Notification/sendNotification";
      var data = new FormData();
      data.append("message", MessageBox);
      data.append("userId", UserId);
      axios
        .post(`${MainUrl + link}`, data)
        // .then((res) => {})
        .catch((err) => {
          console.log(err);
          // setPreloader(false);
        });
    }
  };

  return (
    <>
      {(() => {
        if (isAuthenticate === false) {
          return <Redirect to="/" />;
        }
        return null;
      })()}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ alignItems: "center" }}>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            value={MessageBox}
            onChange={(value) => {
              setMessageBox(value.target.value);
            }}
            style={{ fontSize: 18 }}
            placeholder={"Enter your message"}
            class="form-control form-group"
            id="exampleFormControlTextarea1"
            rows="10"
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              sendMessage();
            }}
            variant="success"
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="col-md-12" style={{ paddingTop: 30 }}>
        <div className="card">
          <div className="header">{/* <h2>Transaction List</h2> */}</div>
          <ul
            className="nav nav-tabs3"
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <li className="nav-item">
              <a
                className="nav-link active show"
                data-toggle="tab"
                href="#Buy-new2"
              >
                Requests
              </a>
            </li>
            <div style={{ paddingBottm: 10 }}>
              <Dropdown>
                <Dropdown.Toggle
                  style={{ backgroundColor: "#F6923A", borderColor: "#F6923A" }}
                  id="dropdown-split-basic"
                >
                  {DropDownState}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setDropDownState("All requests");
                    }}
                  >
                    All requests
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setDropDownState("Accepted");
                    }}
                  >
                    Accepted
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setDropDownState("Order Received");
                    }}
                  >
                    Order Received
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setDropDownState("Rejected");
                    }}
                  >
                    Rejected
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </ul>
          <div className="tab-content mt-0">
            <div className="tab-pane active show" id="Buy-new2">
              <div className="table-responsive">
                <table className="table table-hover table-custom spacing8">
                  <tbody style={{ textAlign: "center" }}>
                    <tr>
                      <td className="w60">
                        <strong>Sr. No.</strong>
                      </td>
                      <td>
                        <p className="mb-0">
                          <strong>Name</strong>
                        </p>
                      </td>
                      <td>
                        <strong>Email</strong>
                      </td>
                      <td>
                        <strong>Phone number</strong>
                      </td>
                      <td>
                        <td>
                          <strong>Status</strong>
                        </td>
                      </td>
                      <td>
                        <strong>Price</strong>
                      </td>
                      <td>
                        <strong>Country</strong>
                      </td>
                      <td>
                        <strong>Action</strong>
                      </td>
                    </tr>
                    {Orders.length
                      ? Orders.map((item, index) => {
                        // item.status
                        if (DropDownState === "All requests") {
                          return (
                            <>
                              <tr>
                                <td className="w60">{index + 1}</td>
                                <td>
                                  <p className="mb-0">
                                    {item.FirstName} {item.LastName}
                                  </p>
                                </td>
                                <td>
                                  <span>{item.EmailId}</span>
                                </td>
                                <td>
                                  <span>{item.Phone}</span>
                                </td>
                                <td>
                                  {item.status === 0 ? (
                                    <td>
                                      <span className="badge badge-warning">
                                        Order Received
                                      </span>
                                    </td>
                                  ) : item.status === 1 ? (
                                    <td>
                                      <span className="badge badge-primary">
                                        Accepted
                                      </span>
                                    </td>
                                  ) : item.status === 2 ? (
                                    <td>
                                      <span className="badge badge-danger">
                                        Rejected
                                      </span>
                                    </td>
                                  ) : item.status === 3 ? (
                                    <td>
                                      <span className="badge badge-warning">
                                        In Process
                                      </span>
                                    </td>
                                  ) : item.status === 4 ? (
                                    <td>
                                      <span className="badge badge-success">
                                        Completed
                                      </span>
                                    </td>
                                  ):null
                                  }
                                </td>
                                <td>
                                  <strong>{item.TotalPayment}</strong>
                                </td>
                                <td className="w100 text-info">
                                  <strong>{item.Country}</strong>
                                </td>
                                <td>
                                  <div style={{ width: "100%" }}>
                                    <div className="row">
                                      <button
                                        type="button"
                                        className="btn btn-default btn-sm mb-0"
                                        style={{ marginRight: 3 }}
                                      >
                                        <Link
                                          style={{
                                            textDecoration: "none",
                                            color: "gray",
                                          }}
                                          to={{
                                            pathname: "/edit",
                                            state: item,
                                          }}
                                        >
                                          <span>View</span>
                                        </Link>
                                      </button>

                                      {
                                        item.status === 4
                                        ?
                                        <button
                                        type="button"
                                        className="btn btn-default btn-sm mb-0"
                                        style={{ marginRight: 3,color:'green' }}
                                        disabled
                                      >
                                          <span>Completed</span>
                                      </button>
                                        :
                                        <Dropdown>
                                        <Dropdown.Toggle
                                          className="btn btn-default btn-sm mb-0"
                                          id="dropdown-split-basic"
                                        >
                                          {/* {DropDownState} */}
                                          Change
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() => {
                                              setDropDownStateFinal(
                                                "Accepted"
                                              );
                                              setItemSelect(item);
                                              alert('Status Updated.')
                                            }}
                                          >
                                            Accept
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => {
                                              // setDropDownState("Order Received");
                                              setDropDownStateFinal(
                                                "Rejected"
                                              );
                                              setItemSelect(item);
                                              alert('Status Updated.')
                                            }}
                                          >
                                            Reject
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => {
                                              // setDropDownState("Order Received");
                                              setDropDownStateFinal(
                                                "In-Process"
                                              );
                                              setItemSelect(item);
                                              alert('Status Updated.')
                                            }}
                                          >
                                            In-Process
                                          </Dropdown.Item>

                                          <Dropdown.Item
                                            onClick={() => {
                                              // setDropDownState("Order Received");
                                              setDropDownStateFinal(
                                                "Completed"
                                              );
                                              setItemSelect(item);
                                              alert('Status Updated.')
                                            }}
                                          >
                                            Completed
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      }
                                      {/* <Link onClick={()=>{alert(JSON.stringify(item.userId))}}> */}
                                      <Link
                                        onClick={() => msgButton(item.userId)}
                                      >
                                        <img
                                          src={MainUrl + "images/message.png"}
                                          height={30}
                                          width={30}
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        } else if (DropDownState === "Accepted") {
                          var i = 0;
                          if (item.status === 1) {
                            return (
                              <>
                                <tr>
                                  <td className="w60">{(i += 1)}</td>
                                  <td>
                                    <p className="mb-0">
                                      {item.FirstName} {item.LastName}
                                    </p>
                                  </td>
                                  <td>
                                    <span>{item.EmailId}</span>
                                  </td>
                                  <td>
                                    <span>{item.Phone}</span>
                                  </td>
                                  <td>
                                    <td>
                                      <span className="badge badge-success">
                                        Accepted
                                      </span>
                                    </td>
                                  </td>
                                  <td>
                                    <strong>{item.TotalPayment}</strong>
                                  </td>
                                  <td className="w100 text-info">
                                    <strong>{item.Country}</strong>
                                  </td>
                                  <td>
                                    <div style={{ width: "100%" }}>
                                      <div className="row">
                                        <button
                                          type="button"
                                          className="btn btn-default btn-sm mb-0"
                                        >
                                          <span>View</span>
                                        </button>

                                        <Dropdown>
                                          <Dropdown.Toggle
                                            className="btn btn-default btn-sm mb-0"
                                            id="dropdown-split-basic"
                                          >
                                            {/* {DropDownState} */}
                                            Change
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            {/* <Dropdown.Item
                                          onClick={() => {
                                            setDropDownState("All requests");
                                          }}
                                        >
                                          All requests
                                        </Dropdown.Item> */}
                                            <Dropdown.Item
                                              onClick={() => {
                                                setDropDownStateFinal(
                                                  "Accepted"
                                                );
                                                setItemSelect(item);
                                                alert('Status Updated.')
                                              }}
                                            >
                                              Accept
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() => {
                                                // setDropDownState("Order Received");
                                                setDropDownStateFinal(
                                                  "Rejected"
                                                );
                                                setItemSelect(item);
                                                alert('Status Updated.')
                                              }}
                                            >
                                              Reject
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          }
                        } else if (DropDownState === "Order Received") {
                          if (item.status === 0) {
                            var i = 0;
                            return (
                              <>
                                <tr>
                                  <td className="w60">{(i += 1)}</td>
                                  <td>
                                    <p className="mb-0">
                                      {item.FirstName} {item.LastName}
                                    </p>
                                  </td>
                                  <td>
                                    <span>{item.EmailId}</span>
                                  </td>
                                  <td>
                                    <span>{item.Phone}</span>
                                  </td>
                                  <td>
                                    <td>
                                      <span className="badge badge-warning">
                                        Order Received
                                      </span>
                                    </td>
                                  </td>
                                  <td>
                                    <strong>{item.TotalPayment}</strong>
                                  </td>
                                  <td className="w100 text-info">
                                    <strong>{item.Country}</strong>
                                  </td>
                                  <td>
                                    <div style={{ width: "100%" }}>
                                      <div className="row">
                                        <button
                                          type="button"
                                          className="btn btn-default btn-sm mb-0"
                                        >
                                          <span>View</span>
                                        </button>

                                        <Dropdown>
                                          <Dropdown.Toggle
                                            className="btn btn-default btn-sm mb-0"
                                            id="dropdown-split-basic"
                                          >
                                            {/* {DropDownState} */}
                                            Change
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            {/* <Dropdown.Item
                                          onClick={() => {
                                            setDropDownState("All requests");
                                          }}
                                        >
                                          All requests
                                        </Dropdown.Item> */}
                                            <Dropdown.Item
                                              onClick={() => {
                                                setDropDownStateFinal(
                                                  "Accepted"
                                                );
                                                setItemSelect(item);
                                                alert('Status Updated.')
                                              }}
                                            >
                                              Accept
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() => {
                                                setDropDownStateFinal(
                                                  "Rejected"
                                                );
                                                setItemSelect(item);
                                                alert('Status Updated.')
                                              }}
                                            >
                                              Reject
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          }
                        } else if (DropDownState === "Rejected") {
                          if (item.status === 2) {
                            var i = 0;
                            return (
                              <>
                                <tr>
                                  <td className="w60">{(i += 1)}</td>
                                  <td>
                                    <p className="mb-0">
                                      {item.FirstName} {item.LastName}
                                    </p>
                                  </td>
                                  <td>
                                    <span>{item.EmailId}</span>
                                  </td>
                                  <td>
                                    <span>{item.Phone}</span>
                                  </td>
                                  <td>
                                    <td>
                                      <span className="badge badge-danger">
                                        Rejected
                                      </span>
                                    </td>
                                  </td>
                                  <td>
                                    <strong>{item.TotalPayment}</strong>
                                  </td>
                                  <td className="w100 text-info">
                                    <strong>{item.Country}</strong>
                                  </td>
                                  <td>
                                    <div style={{ width: "100%" }}>
                                      <div className="row">
                                        <button
                                          type="button"
                                          className="btn btn-default btn-sm mb-0"
                                        >
                                          <span>View</span>
                                        </button>

                                        <Dropdown>
                                          <Dropdown.Toggle
                                            className="btn btn-default btn-sm mb-0"
                                            id="dropdown-split-basic"
                                          >
                                            {/* {DropDownState} */}
                                            Change
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            {/* <Dropdown.Item
                                          onClick={() => {
                                            setDropDownState("All requests");
                                          }}
                                        >
                                          All requests
                                        </Dropdown.Item> */}
                                            <Dropdown.Item
                                              onClick={() => {
                                                setDropDownStateFinal(
                                                  "Accepted"
                                                );
                                                setItemSelect(item);
                                                alert('Status Updated.')
                                              }}
                                            >
                                              Accept
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() => {
                                                // setDropDownState("Rejected");
                                                setDropDownStateFinal(
                                                  "Rejected"
                                                );
                                                setItemSelect(item);
                                                alert('Status Updated.')
                                              }}
                                            >
                                              Reject
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          }
                        }
                      })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
