import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { MainUrl } from "../utils/mainURL";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Login = () => {
  const [logo, setLogo] = useState("images/logo-1e.jpg");
  const toastMessageCall = (msg, position, type) => {
    toast(msg, { type, autoClose: 7000, position, transition: Zoom });
  };
  const [redirect, setRedirect] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const handleSubmit = async (e) => {
    if (email === "") {
      setEmailValid(true);
      setTimeout(() => setEmailValid(false), 2000);
    }
    if (password == "") {
      setPasswordValid(true);
      setTimeout(() => setPasswordValid(false), 2000);
    }
    if (email && password) {
      var link1 = "AuthenticationCMS/adminLogin";
      var data = new FormData();
      data.append("email", email);
      data.append("password", password);
      await axios
        .post(`${MainUrl + link1}`, data)
        .then((res) => {
          var userName = res.data.data.email;
          localStorage.setItem("userName", JSON.stringify(userName));
          setRedirect(true);
        })
        .catch((err) => {
          toastMessageCall(
            err.response.data.responseMessage,
            "top-right",
            "error"
          );
        });
    }
  };

  return (
    <>
      {(() => {
        if (redirect == true) {
          return <Redirect to="/dashboard" />;
        }
      })()}
      <ToastContainer />
      <div className="auth-main particles_js">
        <div className="auth_div vivify popIn">
          <div className="card">
            <div className="body">
              <img
                src={MainUrl + "images/logo-1e.jpg"}
                width="50"
                height="50"
                style={{ borderRadius: 10 }}
                className="d-inline-block align-top mr-2"
                alt="logo"
              />
              <p style={{ color: "black" }} className="lead">
                Welcome to{" "}
              </p>
              <p style={{ color: "black" }} className="lead">
                <span style={{ color: "#F6923A", fontWeight: "bold" }}>
                  Chanakya
                </span>{" "}
                Research
              </p>
              {/* <form className="form-auth-small m-t-20" onSubmit={handleSubmit}> */}
              <div className="form-group">
                <label htmlFor="signin-email" className="control-label sr-only">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control round"
                  id="signin-email"
                  placeholder="Enter User Name"
                  required
                  value={email}
                  onChange={({ target }) => setEmail(target.value)}
                  autoFocus
                />
                {(() => {
                  if (emailValid == true) {
                    return (
                      <span style={{ color: "red" }} className="form-group">
                        User Name required
                      </span>
                    );
                  }
                  return null;
                })()}
              </div>
              <div className="form-group">
                <label
                  htmlFor="signin-password"
                  className="control-label sr-only"
                >
                  Password
                </label>
                <input
                  required
                  type="password"
                  className="form-control round"
                  id="signin-password"
                  placeholder="Enter password"
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                />
                {(() => {
                  if (passwordValid == true) {
                    return (
                      <span style={{ color: "red" }} className="form-group">
                        Password required
                      </span>
                    );
                  }
                  return null;
                })()}
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-round btn-block"
                onClick={handleSubmit}
                form
              >
                Submit
              </button>
              <br />
              <div className="bottom">
                {/* <span className="helper-text m-b-10"><i className="fa fa-lock"></i> <Link to="/forgotpassword">Forgot password</Link></span> */}
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
        <div id="particles-js"></div>
      </div>
    </>
  );
  // }
};

export default Login;
