import React, { useState, useEffect } from "react";
import { Link, Redirect } from 'react-router-dom';
import "./FAQ.css";
import { MainUrl } from "../utils/mainURL";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import { ExtraJs } from './Extra'


const FAQ = () => {
    const [show, setShow] = useState(false);
    const [Question, setQuestion] = useState("");
    const [Answer, setAnswer] = useState("");
    const [Data, setData] = useState([]);
    const [FAQId, setFAQId] = useState();
    const [IsAdd, setIsAdd] = useState(true);
    const [UserId, setUserId] = useState("")
    const [isAuthenticate, setAuthenticate] = useState(true)


    useEffect(() => {
        const token = localStorage.getItem('userName')
        if (token == null) {
            setAuthenticate(false)
        }
        ExtraJs()
    }, [isAuthenticate])


    useEffect(() => {
        var link = "FAQ/getFAQ";
        var data = new FormData();
        data.append("pendingReq", 1);

        axios
            .post(`${MainUrl + link}`, data)
            .then((res) => {
                setData(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleClose = () => {
        setShow(false);
        setQuestion("");
        setAnswer("");
    };

    const editFAQ = (item) => {
        setIsAdd(false);
        setShow(true);
        setQuestion(item.Question);
        setAnswer(item.Answer);
        setFAQId(item._id);
        setUserId(item.userId)

    };

    const editFAQSubmit = () => {
        if (!Question) {
            alert("Enter question first");
        } else if (!Answer) {
            alert("Enter answer first");
        } else {
            //   alert(FAQId)
            var link = "FAQ/editFAQ";
            var data = new FormData();
            data.append("Question", Question);
            data.append("Answer", Answer);
            data.append("FAQId", FAQId);
            data.append("isAnswered", 1)

            axios.post(`${MainUrl + link}`, data).then((res) => {
                setData(res.data.data);
                setShow(false);
                setQuestion("");
                setAnswer("");
                var link = "Notification/sendNotification";
                var data = new FormData();
                data.append("message", 'Your question is answered.');
                data.append("userId", UserId);
                axios.post(`${MainUrl + link}`, data)
            }).catch((err) => {
                console.log(err);
            });
        }
    };

    const deleteFAQ = (FAQId) => {
        var txt;
        if (window.confirm("Do you want to delete?")) {
            var link = "FAQ/deleteFAQ";
            var data = new FormData();
            data.append("pendingReq", 1);
            data.append("FAQId", FAQId);

            axios
                .post(`${MainUrl + link}`, data)
                .then((res) => {
                    setData(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            return 0;
        }
    };

    const AddFAQ = () => {
        if (!Question) {
            alert("Enter question first");
        } else if (!Answer) {
            alert("Enter answer first");
        } else {
            var link = "FAQ/addFAQ";
            var data = new FormData();
            data.append("Question", Question);
            data.append("Answer", Answer);

            axios.post(`${MainUrl + link}`, data).then((res) => {
                setData(res.data.data);
                setShow(false);
                setQuestion("");
                setAnswer("");
            }).catch((err) => {
                console.log(err);
            });
        }
    };


    useEffect(() => {
        const script = document.createElement('babel');

        script.src = "../FAQ/Extra.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);
    return (
        <div>
            {(() => {
                if (isAuthenticate === false) {
                    return (<Redirect to='/' />)
                }
                return null
            })()}

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ alignItems: "center", fontSize: 20 }}>
                        FAQ
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea
                        value={Question}
                        onChange={(value) => {
                            setQuestion(value.target.value);
                        }}
                        style={{ fontSize: 18 }}
                        placeholder={"Enter Question"}
                        className="form-control form-group"
                        id="exampleFormControlTextarea1"
                        rows="3"
                    />
                    <textarea
                        value={Answer}
                        onChange={(value) => {
                            setAnswer(value.target.value);
                        }}
                        style={{ fontSize: 18 }}
                        placeholder={"Enter Answer"}
                        className="form-control form-group"
                        id="exampleFormControlTextarea1"
                        rows="3"
                    />
                </Modal.Body>
                <Modal.Footer>
                    {IsAdd === true ? (
                        <Button
                            onClick={() => {
                                AddFAQ();
                            }}
                            variant="success"
                        >
                            Add
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                editFAQSubmit();
                            }}
                            variant="success"
                        >
                            Approved
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>



            <aside className="accordion">
                <h1>
                    Pending Requests{" "}
                    <p
                        style={{ float: "right" }}
                        onClick={() => {
                            //   alert(44);
                            setIsAdd(true);
                            setShow(true);
                        }}
                    >
                    </p>{" "}

                </h1>
                <div className="opened-for-codepen">
                    {Data.map((item, index) => {
                        return (
                            <>
                                <h2>
                                    {item.Question}{" "}
                                    <p style={{ float: "right" }}>
                                        <i
                                            className="fa fa-pencil"
                                            aria-hidden="true"
                                            style={{ paddingRight: 30 }}
                                            onClick={() => {
                                                editFAQ(item);
                                            }}
                                        />{" "}
                                        <i
                                            className="fa fa-trash-o"
                                            aria-hidden="true"
                                            onClick={() => {
                                                deleteFAQ(item._id);
                                            }}
                                        />
                                    </p>
                                </h2>
                                <div className={'answer'}>{item.Question}</div>
                            </>
                        );
                    })}
                </div>
            </aside>
        </div>
    );
};

export default FAQ;
