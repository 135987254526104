import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import { MainUrl } from "../utils/mainURL";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const RegisteredUsersDetails = () => {

    const [isAuthenticate, setAuthenticate] = useState(true);
    const [UsersList, setUsersList] = useState(true);
    useEffect(() => {
        const token = localStorage.getItem("userName");
        if (token == null) {
            setAuthenticate(false);
        }
    }, []);

    useEffect(() => {
        var link = "usersDetails/getUsers";
        axios
            .post(`${MainUrl + link}`)
            .then((res) => {
                setUsersList(res.data.data);
                console.log(res.data.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }, [])
    return (
        <>
            {(() => {
                if (isAuthenticate === false) {
                    return <Redirect to="/" />;
                }
                return null;
            })()}
            <table className="table table-hover table-custom spacing8">
                <tbody style={{ textAlign: "center" }}>
                    <tr>
                        <td className="w60">
                            <strong>Sr. No.</strong>
                        </td>
                        <td>
                            <p className="mb-0">
                                <strong>Name</strong>
                            </p>
                        </td>
                        <td>
                            <strong>Email</strong>
                        </td>
                        <td>
                            <strong>Profile Photo</strong>
                        </td>
                    </tr>
                    {
                        UsersList.length ?
                        UsersList.map((item, index) => (
                            <tr>
                                <td className="w60">
                                    <strong>{index + 1}</strong>
                                </td>
                                <td>
                                    <span>{item.name}</span>
                                </td>
                                <td>
                                    <span>{item.email}</span>
                                </td>
                                <td>
                                    <img src={MainUrl + item.profile_image} style={{ borderRadius: 10 }} height={70} width={70}/>
                                </td>
                            </tr>
                        ))
                        :null
                    }
                </tbody>
            </table>
        </>
    )

}

export default RegisteredUsersDetails;
