import React, { useState, useEffect } from "react";
import "../FAQ/FAQ.css";
import { MainUrl } from "../utils/mainURL";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, Redirect } from "react-router-dom";
import CKEditor from "ckeditor4-react";
import { ExtraJs } from "../FAQ/Extra";

const Knowledge = () => {
  const [show, setShow] = useState(false);
  const [Question, setQuestion] = useState("");
  const [Answer, setAnswer] = useState("");
  const [Data, setData] = useState([]);
  const [ACKId, setACKId] = useState();
  const [IsAdd, setIsAdd] = useState(true);
  const [isAuthenticate, setAuthenticate] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("userName");
    if (token == null) {
      setAuthenticate(false);
    }
    ExtraJs();
  }, [isAuthenticate]);

  useEffect(() => {
    var link = "Ack/getAck";
    axios
      .post(`${MainUrl + link}`)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleClose = () => {
    setShow(false);
    setQuestion("");
    setAnswer("");
  };

  const editACK = (item) => {
    setIsAdd(false);
    setShow(true);
    setQuestion(item.Question);
    setAnswer(item.Answer);
    setACKId(item._id);
  };

  const editACKSubmit = () => {
    if (!Question) {
      alert("Enter question first");
    } else if (!Answer) {
      alert("Enter answer first");
    } else {
      var link = "Ack/editAck";
      var data = new FormData();
      data.append("Question", Question);
      data.append("Answer", Answer);
      data.append("ACKId", ACKId);

      axios
        .post(`${MainUrl + link}`, data)
        .then((res) => {
          setData(res.data.data);
          setShow(false);
          setQuestion("");
          setAnswer("");
          alert(res.data.responseMessage);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteACK = (ACKId) => {
    var txt;
    if (window.confirm("Do you want to delete?")) {
      var link = "Ack/deleteAck";
      var data = new FormData();
      data.append("ACKId", ACKId);

      axios
        .post(`${MainUrl + link}`, data)
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return 0;
    }
  };

  const AddACK = () => {
    if (!Question) {
      alert("Enter question first");
    } else if (!Answer) {
      alert("Enter answer first");
    } else {
      var link = "Ack/addACK";
      var data = new FormData();
      data.append("Question", Question);
      data.append("Answer", Answer);

      axios
        .post(`${MainUrl + link}`, data)
        .then((res) => {
          setData(res.data.data);
          setShow(false);
          setQuestion("");
          setAnswer("");
          var link = "Notification/SendToAllNotification";
          axios
            .post(`${MainUrl + link}`)
            .then((res) => {
              console.log(res.data)
            })
            .catch((err) => {
              console.log(err);
              // setPreloader(false);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const script = document.createElement("babel");

    script.src = "../FAQ/Extra.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  function handleBlogContantUpload(evt) {
    setAnswer(evt.editor.getData());
  }

  return (
    <div>
      {(() => {
        if (isAuthenticate === false) {
          return <Redirect to="/" />;
        }
        return null;
      })()}
      <Modal
        enforceFocus={false}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ alignItems: "center", fontSize: 20 }}>
            Knowledge Center
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            value={Question}
            onChange={(value) => {
              setQuestion(value.target.value);
            }}
            style={{ fontSize: 18 }}
            placeholder={"Enter Question"}
            className="form-control form-group"
            id="exampleFormControlTextarea1"
            rows="2"
          />
          <CKEditor data={Answer} onChange={handleBlogContantUpload} />
        </Modal.Body>
        <Modal.Footer>
          {IsAdd === true ? (
            <Button
              onClick={() => {
                AddACK();
              }}
              variant="success"
            >
              Add
            </Button>
          ) : (
            <Button
              onClick={() => {
                editACKSubmit();
              }}
              variant="danger"
            >
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <aside className="accordion">
        <h1>
          Knowledge Center{" "}
          <p
            style={{ float: "right" }}
            onClick={() => {
              setIsAdd(true);
              setShow(true);
            }}
          >
            <i
              style={{ textDecoration: "none" }}
              className="fa fa-plus"
              aria-hidden="true"
            ></i>
          </p>{" "}
        </h1>
        <div className="opened-for-codepen">
          {Data.map((item, index) => {
            return (
              <>
                <h2>
                  {item.Question}{" "}
                  <p style={{ float: "right" }}>
                    <i
                      className="fa fa-pencil"
                      aria-hidden="true"
                      style={{ paddingRight: 30 }}
                      onClick={() => {
                        editACK(item);
                      }}
                    />{" "}
                    <i
                      className="fa fa-trash-o"
                      aria-hidden="true"
                      onClick={() => {
                        deleteACK(item._id);
                      }}
                    />
                  </p>
                </h2>
                {/* <p dangerouslySetInnerHTML={{ __html: item.Answer }} /> */}
                {/* <p dangerouslySetInnerHTML={{ __html: item.Answer }}/> */}
                <div
                  className={"answer"}
                  dangerouslySetInnerHTML={{ __html: item.Answer }}
                >
                  {/* <p>{item.Answer}</p> */}
                  {/* <p dangerouslySetInnerHTML={{ __html: item.Answer }} /> */}
                </div>
              </>
            );
          })}
        </div>
        {/* <h1>Miscellaneous</h1> */}
        <div>
          <h2>Misc. #1</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
          <h2>Misc. #2</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
          <h2>Misc. #3</h2>
          <div>
            <h3>Misc. Item #1a</h3>
            <div>
              <h4>Misc. Subitem 1</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <h4>Misc. Subitem 2</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <h4>Misc. Subitem 3</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <h3>Misc. Item #2a</h3>
            <div>
              <h4>Misc. Subitem 1</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <h4>Misc. Subitem 2</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Knowledge;
