
import Dashboard from './Dashboard/dashboard';
import editForm from './Dashboard/editForm';
import FAQ from '../components/FAQ/FAQ';
import Knowledge from './KnowledgCenter/Knowledge';
import RegisteredUsersDetails from './RegisteredUsers/RegisteredUsersDetails';
import ViewRequest from './FAQ/ViewRequests'
const Routes = [
    {
        path: "/dashboard",
        name: 'dashboard',
        exact: true,
        pageTitle: "Dashboard",
        component: Dashboard
    },
    {
        path: "/edit",
        name: 'edit',
        exact: true,
        pageTitle: "Edit",
        component: editForm
    },
    {
        path: "/faq",
        name: 'FAQ',
        exact: true,
        pageTitle: "FAQ\'s",
        component: FAQ
    },
    {
        path: "/Knowledge",
        name: 'Knowledge',
        exact: true,
        pageTitle: "Knowledge",
        component: Knowledge
    },
    {
        path: "/users",
        name: 'Users',
        exact: true,
        pageTitle: "Users",
        component: RegisteredUsersDetails
    },
    {
        path: "/requests",
        name: 'Requests',
        exact: true,
        pageTitle: "Requests",
        component: ViewRequest
    }
];

export default Routes;