import React, { useState, useEffect } from "react";
import { Link, Redirect } from 'react-router-dom';
import "./FAQ.css";
import { MainUrl } from "../utils/mainURL";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import { ExtraJs } from './Extra'


const FAQ = () => {
   const [show, setShow] = useState(false);
   const [Question, setQuestion] = useState("");
   const [Answer, setAnswer] = useState("");
   const [Data, setData] = useState([]);
   const [FAQId, setFAQId] = useState();
   const [IsAdd, setIsAdd] = useState(true);
   const [isAuthenticate, setAuthenticate] = useState(true)


   useEffect(() => {
      const token = localStorage.getItem('userName')
      if (token == null) {
         setAuthenticate(false)
      }
      ExtraJs()
   }, [isAuthenticate])


   useEffect(() => {
      var link = "FAQ/getFAQ";
      axios
         .post(`${MainUrl + link}`)
         .then((res) => {
            setData(res.data.data);
         })
         .catch((err) => {
            console.log(err);
         });
   }, []);

   const handleClose = () => {
      setShow(false);
      setQuestion("");
      setAnswer("");
   };

   const editFAQ = (item) => {
      setIsAdd(false);
      setShow(true);
      setQuestion(item.Question);
      setAnswer(item.Answer);
      setFAQId(item._id);
   };

   const editFAQSubmit = () => {
      if (!Question) {
         alert("Enter question first");
      } else if (!Answer) {
         alert("Enter answer first");
      } else {
         //   alert(FAQId)
         var link = "FAQ/editFAQ";
         var data = new FormData();
         data.append("Question", Question);
         data.append("Answer", Answer);
         data.append("FAQId", FAQId);

         axios
            .post(`${MainUrl + link}`, data)
            .then((res) => {
               setData(res.data.data);
               setShow(false);
               setQuestion("");
               setAnswer("");
            })
            .catch((err) => {
               console.log(err);
            });
      }
   };

   const deleteFAQ = (FAQId) => {
      var txt;
      if (window.confirm("Do you want to delete?")) {
         var link = "FAQ/deleteFAQ";
         var data = new FormData();
         data.append("FAQId", FAQId);

         axios
            .post(`${MainUrl + link}`, data)
            .then((res) => {
               setData(res.data.data);
            })
            .catch((err) => {
               console.log(err);
            });
      } else {
         return 0;
      }
   };

   const AddFAQ = () => {
      if (!Question) {
         alert("Enter question first");
      } else if (!Answer) {
         alert("Enter answer first");
      } else {
         var link = "FAQ/addFAQ";
         var data = new FormData();
         data.append("Question", Question);
         data.append("Answer", Answer);

         axios
            .post(`${MainUrl + link}`, data)
            .then((res) => {
               setData(res.data.data);
               setShow(false);
               setQuestion("");
               setAnswer("");
            })
            .catch((err) => {
               console.log(err);
            });
      }
   };


   useEffect(() => {
      const script = document.createElement('babel');

      script.src = "../FAQ/Extra.js";
      script.async = true;

      document.body.appendChild(script);

      return () => {
         document.body.removeChild(script);
      }
   }, []);
   return (
      <div>
         {(() => {
            if (isAuthenticate === false) {
               return (<Redirect to='/' />)
            }
            return null
         })()}

         <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
         >
            <Modal.Header closeButton>
               <Modal.Title style={{ alignItems: "center", fontSize: 20 }}>
                  FAQ
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <textarea
                  value={Question}
                  onChange={(value) => {
                     setQuestion(value.target.value);
                  }}
                  style={{ fontSize: 18 }}
                  placeholder={"Enter Question"}
                  className="form-control form-group"
                  id="exampleFormControlTextarea1"
                  rows="3"
               />
               <textarea
                  value={Answer}
                  onChange={(value) => {
                     setAnswer(value.target.value);
                  }}
                  style={{ fontSize: 18 }}
                  placeholder={"Enter Answer"}
                  className="form-control form-group"
                  id="exampleFormControlTextarea1"
                  rows="3"
               />
            </Modal.Body>
            <Modal.Footer>
               {IsAdd === true ? (
                  <Button
                     onClick={() => {
                        AddFAQ();
                     }}
                     variant="success"
                  >
                     Add
                  </Button>
               ) : (
                  <Button
                     onClick={() => {
                        editFAQSubmit();
                     }}
                     variant="danger"
                  >
                     Update
                  </Button>
               )}
            </Modal.Footer>
         </Modal>

         {/* <div style={{ float: "right" ,backgroundColor:'red',position:'absolute',display:'block'}}>
                     Shadab
         </div> */}
         <div style={{display: "flex",
            justifyContent: 'flex-end',}}>
         <Link to="/requests" style={{
            backgroundColor: '#F6923A', 
            width: 150, 
            marginTop: 30, 
            textAlign: 'center', 
            height: 40, 
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // box-shadow: 12px 12px 2px 1px
            boxShadow: "1px 3px 3px #9E9E9E",
            borderRadius:5
            // float:'right'
         }}>
            <span style={{color:'black',fontSize:20,marginRight:15}}>Requests</span>
            <div >
            <img src="http://65.0.3.36:4001/images/bell.png" width={30} height={30} />
            {/* <span style={{position:'absolute',right:30,fontSize: 12,color:'black'}}>9+</span> */}
            </div>
         </Link>
         {/* <Button
            // onClick={() => {
            //   sendMessage();
            // }}
            style={{backgroundColor:'#F6923A',borderColor:'#F6923A',marginTop:30}}
            variant="success"
          >
            Send
          </Button> */}
         </div>
         
         <aside className="accordion">
            <h1>
               FAQ{" "}
               <p
                  style={{ float: "right" }}
                  onClick={() => {
                     //   alert(44);
                     setIsAdd(true);
                     setShow(true);
                  }}
               >
                  <i
                     style={{ textDecoration: "none" }}
                     className="fa fa-plus"
                     aria-hidden="true"
                  ></i>
                  {/* <img height={20} width={20} src={MainUrl + "/images/Plus.png"} /> */}
               </p>{" "}

            </h1>
            <div className="opened-for-codepen">
               {Data.map((item, index) => {
                  return (
                     <>
                        <h2>
                           {item.Question}{" "}
                           <p style={{ float: "right" }}>
                              <i
                                 className="fa fa-pencil"
                                 aria-hidden="true"
                                 style={{ paddingRight: 30 }}
                                 onClick={() => {
                                    editFAQ(item);
                                 }}
                              />{" "}
                              <i
                                 className="fa fa-trash-o"
                                 aria-hidden="true"
                                 onClick={() => {
                                    deleteFAQ(item._id);
                                 }}
                              />
                           </p>
                        </h2>
                        <div className={'answer'}>{item.Answer}</div>
                     </>
                  );
               })}
            </div>
            {/* <h1>Miscellaneous</h1> */}
            {/* <div>
               <h2>Misc. #1</h2>
               <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                  ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat.
               </p>
               <h2>Misc. #2</h2>
               <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                  ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat.
               </p>
               <h2>Misc. #3</h2>
               <div>
                  <h3>Misc. Item #1a</h3>
                  <div>
                     <h4>Misc. Subitem 1</h4>
                     <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                        enim ad minim veniam, quis nostrud exercitation ullamco laboris
                        nisi ut aliquip ex ea commodo consequat.
                     </p>
                     <h4>Misc. Subitem 2</h4>
                     <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                        enim ad minim veniam, quis nostrud exercitation ullamco laboris
                        nisi ut aliquip ex ea commodo consequat.
                     </p>
                     <h4>Misc. Subitem 3</h4>
                     <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                        enim ad minim veniam, quis nostrud exercitation ullamco laboris
                        nisi ut aliquip ex ea commodo consequat.
                     </p>
                  </div>
                  <h3>Misc. Item #2a</h3>
                  <div>
                     <h4>Misc. Subitem 1</h4>
                     <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                        enim ad minim veniam, quis nostrud exercitation ullamco laboris
                        nisi ut aliquip ex ea commodo consequat.
                     </p>
                     <h4>Misc. Subitem 2</h4>
                     <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                        enim ad minim veniam, quis nostrud exercitation ullamco laboris
                        nisi ut aliquip ex ea commodo consequat.
                     </p>
                  </div>
               </div>
            </div> */}
         </aside>
      </div>
   );
};

export default FAQ;
