import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import { MainUrl } from "../utils/mainURL";
import "./custom.css";
import Dropdown from "react-bootstrap/Dropdown";
import JsFileDownloader from 'js-file-downloader';

const EditForm = (props) => {
  // alert(JSON.stringify(props.location))
  // AOS.init()
  const [Preloader, setPreloader] = useState(true);
  const [isAuthenticate, setAuthenticate] = useState(true);
  const [Orders, setOrders] = useState([]);
  const [DropDownState, setDropDownState] = useState("");
  const [DropDownStateFinal, setDropDownStateFinal] = useState();
  const [DropDownColor, setDropDownColor] = useState("");
  const [FormDatas, setFormData] = useState({});
  const [UpdateRedirect, setUpdateRedirect] = useState(false);

  useEffect(() => {
    // console.log(props)
    // if (props.location.hasOwnProperty('state')) {
    //   alert('123')
    // }
    // else {
    //   return <Redirect to="/dashboard" />;
    // }
    // alert("🚀 ~ file: editForm.js ~ line 24 ~ useEffect ~ props", props)
    if (!props.location.state) {
      return <Redirect to="/dashboard" />;
    } else {
      setFormData(props.location.state);
      if (props.location.state.status === 0) {
        setDropDownState("Processing");
        setDropDownColor("#FFC108");
      }
      if (props.location.state.status === 1) {
        setDropDownState("Accepted");
        setDropDownColor("green");
      }
      if (props.location.state.status === 2) {
        setDropDownState("Rejected");
        setDropDownColor("red");
      }
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("userName");
    if (token == null) {
      setAuthenticate(false);
    }
  }, [isAuthenticate]);

  useEffect(() => {
    var link = "Form/updateOrders";
    var data = new FormData();
    if (DropDownStateFinal) {
      if (DropDownStateFinal === "Accepted") {
        var status = 1;
      } else {
        var status = 2;
      }
      data.append("orderId", props.location.state._id);
      data.append("status", status);

      axios
        .post(`${MainUrl + link}`, data)
        .then((res) => {
          setUpdateRedirect(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [DropDownStateFinal]);

  const acceptRejectNotify = (userId, status) => {
    if (status === 1) {
      var message = 'Your request is accepted. We will contact you soon.'
    }
    else {
      var message = 'Your request is rejected. We will contact you soon.'
    }
    var link = "Notification/sendNotification";
    var data = new FormData();
    data.append("message", message);
    data.append("userId", userId);
    axios
      .post(`${MainUrl + link}`, data)
      // .then((res) => {})
      .catch((err) => {
        console.log(err);
        // setPreloader(false);
      });
  }

  const downloadFile = (fileUrl) => {
    new JsFileDownloader({
      url: fileUrl
    })
      .then(function () {
        // Called when download ended
        console.log('Complete')
      })
      .catch(function (error) {
        // Called when an error occurred
        console.log('error')
      });
  }

  return (
    <>
      {(() => {
        if (isAuthenticate === false) {
          return <Redirect to="/" />;
        }
        if (UpdateRedirect === true) {
          alert("Order Updated");
          return <Redirect to="/dashboard" />;
        }
        return null;
      })()}
      <div className="col-lg-12 col-md-8 col-sm-12">
        <div className="card">
          <div className="header"></div>
          <div className="body">
            <div id="chart-temperature">
              <div className="row">
                <div className="col-md-4 ">
                  <lable>First Name</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.FirstName}
                  />
                </div>
                <div className="col-md-4">
                  <lable>Last Name</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.LastName}
                  />
                </div>
                <div className="col-md-4">
                  <lable>Email Address</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.EmailId}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 ">
                  <lable>Country</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.Country}
                  />
                </div>
                <div className="col-md-6">
                  <lable>Phone number</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.Phone}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 ">
                  <lable>Academic Level</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.AcademicLevel}
                  />
                </div>
                <div className="col-md-4">
                  <lable>Document Type</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.DocumentType}
                  />
                </div>
                <div className="col-md-4">
                  <lable>Service Type</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.ServiceType}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 ">
                  <lable>Number Of Pages</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.NumberOfPages + " pages"}
                  />
                </div>
                <div className="col-md-4">
                  <lable>Expected Delivery</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.ExpectedDelieveryDate}
                  />
                </div>
                <div className="col-md-4">
                  <lable>Revision Plane</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.RevisionPlane}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 ">
                  <lable>Statical Analysis</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.StaticalAnalysis}
                  />
                </div>
                <div className="col-md-4">
                  <lable>Plagiarism Report</lable>
                  {FormDatas.PlagiarismReport === true ? (
                    <input
                      type="text"
                      className="form-control form-group"
                      aria-label="Username"
                      disabled
                      value={"Yes"}
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control form-group"
                      aria-label="Username"
                      disabled
                      value={"No"}
                    />
                  )}
                </div>
                <div className="col-md-4">
                  <lable>Revision Plane</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.RevisionPlane}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <lable>Subject Area</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.SubjectArea}
                  />
                </div>
                <div className="col-md-6">
                  <lable>Reffering Style</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.RefferingStyle}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <lable>Required Source</lable>
                  <textarea
                    className="form-control form-group"
                    disabled
                    rows={5}
                    cols={5}
                    value={FormDatas.RequiredSource}
                  ></textarea>
                </div>
                <div className="col-md-6">
                  <lable>Instruction Order</lable>
                  <textarea
                    className="form-control form-group"
                    disabled
                    rows={5}
                    cols={5}
                    value={FormDatas.InstructionOrder}
                  ></textarea>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 ">
                  <lable>Tota lPayment</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.TotalPayment}
                  />
                </div>
                <div className="col-md-4">
                  <lable>Discount Final Price</lable>
                  <input
                    type="text"
                    className="form-control form-group"
                    aria-label="Username"
                    disabled
                    value={FormDatas.DiscountFinalPrice}
                  />
                </div>
                <div className="col-md-4">
                  <lable>Status</lable>
                  {/* {FormDatas.status === 0 ? (
                    <span className="badge badge-warning">Processing</span>
                  ) : FormDatas.status === 1 ? (
                    <span className="badge badge-success">Accepted</span>
                  ) : (
                    <span className="badge badge-danger">Rejected</span>
                  )} */}
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        backgroundColor: DropDownColor,
                        borderColor: DropDownColor,
                        width: "100%",
                        fontWeight: "bold",
                      }}
                      id="dropdown-split-basic"
                    >
                      {DropDownState}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          // setDropDownState("Accepted", () => {
                          //     alert('123')
                          //     changeStatus();
                          //  });
                          setDropDownState("Accepted");
                          setDropDownStateFinal("Accepted");
                          setDropDownColor("green");
                          acceptRejectNotify(FormDatas.userId, 1)
                          //   changeStatus();
                        }}
                      >
                        Accepted
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setDropDownState("Rejected");
                          setDropDownStateFinal("Rejected");
                          setDropDownColor("red");
                          acceptRejectNotify(FormDatas.userId, 2)
                        }}
                      >
                        Rejected
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="form-group">
                <tr>
                  {
                    
                    props.location.state.Files.map((item, index) => {
                      var extension = item.substr(item.lastIndexOf(".") + 1);
                      if (extension === "jpg") {
                        return (
                          <td colspan="6">
                            <div style={{ width: 200, margin: 20 }}>
                              <img
                                src={MainUrl + "images/jpeg.png"}
                                height={50}
                                width={50}
                                style={{ margin: 20 }}
                                target="blank"
                              />
                              <p>{item}</p>
                              <div className="row">
                                <div className="col-md-6">
                                  <a
                                    target="_blank"
                                    href={MainUrl + item}
                                    className="btn btn-default btn-sm mb-0"
                                    style={{width:100}}
                                  >
                                    View
                                  </a>
                                </div>
                                <div className="col-md-6">
                                  <button
                                    type="button"
                                    className="btn btn-default btn-sm mb-0"
                                    onClick={() => { downloadFile(MainUrl + item) }}
                                    style={{width:100}}
                                  >
                                    <span>Download</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                        );
                      }
                      if (extension === "pdf") {
                        return (
                          <td colspan="6">
                            <div style={{ width: 200, margin: 20 }}>
                              <img
                                src={MainUrl + "images/file.png"}
                                height={50}
                                width={50}
                                style={{ margin: 20 }}
                                target="blank"
                              />
                              <p>{item}</p>
                              <div className="row">
                                <div className="col-md-6">
                                  <a
                                    target="_blank"
                                    href={MainUrl + item}
                                    className="btn btn-default btn-sm mb-0"
                                    style={{width:100}}
                                  >
                                    View
                                  </a>
                                </div>
                                <div className="col-md-6">
                                  <button
                                    type="button"
                                    className="btn btn-default btn-sm mb-0"
                                    onClick={() => { downloadFile(MainUrl + item) }}
                                    style={{width:100}}
                                  >
                                    <span>Download</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                        );
                      }
                      if (extension === "xls" || extension === "xlsx") {
                        return (
                          <td colspan="6">
                            <div style={{ width: 200, margin: 20 }}>
                              <img
                                src={MainUrl + "images/xls.png"}
                                height={50}
                                width={50}
                                style={{ margin: 20 }}
                                target="blank"
                              />
                              <p>{item}</p>
                              <div className="row">
                                <div className="col-md-6">
                                  <a
                                    target="_blank"
                                    href={MainUrl + item}
                                    className="btn btn-default btn-sm mb-0"
                                    style={{width:100}}
                                  >
                                    View
                                  </a>
                                </div>
                                <div className="col-md-6">
                                  <button
                                    type="button"
                                    className="btn btn-default btn-sm mb-0"
                                    onClick={() => { downloadFile(MainUrl + item) }}
                                    style={{width:100}}
                                  >
                                    <span>Download</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                        );
                      } else {
                        return (
                          <td colspan="6">
                            <div style={{ width: 200, margin: 20 }} >
                              <img
                                src={MainUrl + "images/doc.png"}
                                height={50}
                                width={50}
                                style={{ margin: 20 }}
                                target="blank"
                              />
                              <p>{item}</p>
                              <div className="row">
                                <div className="col-md-6">
                                  <a
                                    target="_blank"
                                    href={MainUrl + item}
                                    className="btn btn-default btn-sm mb-0"
                                    style={{width:100}}
                                  >
                                    View
                                  </a>
                                </div>
                                <div className="col-md-6">
                                  <button
                                    type="button"
                                    className="btn btn-default btn-sm mb-0"
                                    onClick={() => { downloadFile(MainUrl + item) }}
                                    style={{width:100}}
                                  >
                                    <span>Download</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                        );
                      }
                    })}
                </tr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditForm;
